.container {
  @apply flex justify-between;
  @apply border border-solid border-base-80  p-[10px] gap-6;
}

.wrapper {
  @apply flex gap-2 w-full;
}

.buttons {
  @apply flex gap-2 items-center;
}

.row {
  @apply flex gap-1 items-center;
}

.label {
  @apply text-xs font-medium whitespace-nowrap;
}
.value {
  @apply text-sm font-semibold text-text-50;
}

.content {
  @apply grid grid-cols-[90px_156px_100px] justify-between w-full items-center;
}

.name {
  @apply text-sm font-medium;
}

.exclusive {
  @apply text-xs font-medium text-primary-70;
}

.col {
  @apply flex flex-col;
}
