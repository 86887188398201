/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.spinner {
  @apply flex items-center justify-center gap-2;
  @apply text-primary-100 text-lg font-medium;
  @apply mb-4;
}

.success {
  @apply text-status-approved text-lg font-medium;
}

.error {
  @apply text-system-error text-lg font-medium;
}

.btn {
  @apply mt-4;
}
