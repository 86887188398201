/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.main {
  @apply flex flex-col gap-y-8;
}

.container {
  @apply flex flex-col xl:flex-row gap-8;
}

.container > div {
  @apply flex-1;
}

.container > div:nth-child(2n) {
  @apply flex-grow-[2];
}

.section {
  @apply flex flex-col gap-y-4;
}

.section > span {
  @apply text-xl font-semibold;
}

.chart {
  @apply relative;
  @apply h-[220px];
  @apply min-w-[210px];
}
