/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.main {
  @apply relative;
  @apply border border-base-80 rounded-xl;
  @apply w-full;
  @apply flex flex-col;
  @apply px-3 py-2;
  @apply transition-colors duration-200;
}

.main:hover {
  @apply border-text-70;
}

.main:hover > .copy_btn {
  @apply opacity-100;
}

.label {
  @apply text-sm;
  @apply text-text-40;
}

.value {
  @apply font-medium;
}

.copy_btn {
  @apply absolute top-0 bottom-0 right-0;
  @apply transition-opacity duration-200;
  @apply opacity-0;
  @apply flex items-center justify-between;
  @apply mx-4;
}

.copy_btn > button {
  @apply bg-base-90 text-text-40 rounded-full;
  @apply p-3;
}
