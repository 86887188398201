/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.main {
  @apply flex flex-col h-[80vh] justify-center;
  @apply w-full max-w-80;
  @apply mx-auto;
  @apply my-6;
}

.wrapContent {
  @apply flex flex-col gap-4 bg-base-90/80 p-8 py-6 rounded-xl;
}
