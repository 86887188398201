/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.main {
  @apply w-full;
}

.container {
  @apply rounded-xl overflow-hidden;
  @apply flex items-center gap-2;
  @apply border border-base-80;
  @apply transition-colors duration-200;
}

.container:not(._disabled):focus-within,
.container:not(._disabled):hover {
  @apply border-primary-100;
}

.container svg {
  @apply text-lg;
}

.text_area {
  @apply w-full outline-none;
  @apply text-base;
  @apply resize-y;
  @apply min-h-20;
  @apply px-3 py-2;
}

.text_area::placeholder {
  @apply text-sm;
  @apply opacity-100;
  @apply font-normal;
}

.error {
  @apply text-system-error;
  @apply px-1;
  @apply text-sm;
}

.primary .container,
.primary .text_area {
  @apply bg-base-100 text-text-100;
}

.primary .text_area::placeholder {
  @apply text-text-40;
}

.secondary .container,
.secondary .text_area {
  @apply bg-base-90 text-text-100;
}

.secondary .text_area::placeholder {
  @apply text-text-40/80;
}

.container._disabled,
.container._disabled .text_area {
  @apply bg-base-90;
}
