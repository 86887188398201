/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.ant-pagination .ant-pagination-item {
  @apply font-primary;
  @apply text-text-70 font-medium;
}

.ant-pagination .ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination .ant-pagination-disabled:hover .ant-pagination-item-link {
  @apply text-text-70 opacity-60;
}

.ant-pagination .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination .ant-pagination-next .ant-pagination-item-link {
  @apply text-text-70 bg-transparent hover:!bg-transparent;
}

.ant-pagination .ant-pagination-item {
  @apply text-text-70 bg-transparent;
  @apply hover:!bg-transparent;
}

.ant-pagination .ant-pagination-item-active,
.ant-pagination .ant-pagination-item-active:hover {
  @apply !bg-primary-100;
  @apply border-none;
  @apply rounded-xl;
  @apply transition-colors duration-100;
}

.ant-pagination .ant-pagination-item-active a,
.ant-pagination .ant-pagination-item-active:hover a {
  @apply !text-base-100;
  @apply transition-colors duration-100;
}

.ant-pagination
  .ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-link-icon,
.ant-pagination
  .ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-link-icon {
  @apply !text-text-70;
}

.ant-pagination
  .ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis,
.ant-pagination
  .ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis {
  @apply !text-text-40;
}
