/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.ant-checkbox-wrapper {
  @apply text-sm text-text-70;
}

.ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
  @apply border-primary-100;
  @apply bg-primary-100;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  @apply border-primary-100;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  @apply bg-primary-100;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  @apply bg-primary-100;
}
