.container {
  @apply flex flex-col gap-3;
}

.container h4 {
  @apply text-xl;
}

.col {
  @apply flex flex-col;
}

.buttons {
  @apply grid grid-cols-2 gap-2 mt-auto;
}

.list {
  @apply flex flex-col gap-2 mb-5 min-w-[500px];
}

.row {
  @apply flex justify-between items-center;
}

.description {
  @apply text-sm text-base-60;
}

.wrapper {
  @apply flex items-center gap-4;
}
