/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.main {
  @apply flex flex-col;
}

.picker_container {
  @apply relative;
  @apply flex items-center w-full justify-between;
}

.error {
  @apply absolute bottom-0 translate-y-[100%];
  @apply px-1;
  @apply text-system-error text-sm;
}
