/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.apply_btn {
  @apply mt-auto mb-4;
}

.title {
  @apply font-semibold -mb-1;
}

.datePickWrap {
  @apply flex gap-2 mt-0;
}

.dateCol {
  @apply flex flex-col mb-0;
  @apply min-w-[48%];
}

.section {
  @apply mb-4;
}

.dateCol h4 {
  @apply text-text-70/50 mb-1 font-medium text-xs;
}

.dateCol h2 {
  @apply text-text-70/50 mb-2 font-medium text-xs;
}
