.buttons {
  @apply flex flex-col gap-2 mt-auto;
}
.container {
  @apply flex flex-col flex-[1_1] gap-4;
}

.content {
  @apply flex flex-col gap-6;
}

.amount {
  @apply flex items-center gap-1;
}

.value {
  @apply text-base font-semibold;
}
