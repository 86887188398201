/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.card {
  @apply relative;
  @apply bg-base-100 rounded-2xl;
  @apply px-5 py-6;
  @apply flex flex-col gap-y-6;
  @apply overflow-hidden;
}

.card h1 {
  @apply font-bold text-2xl;
}

.card h2 {
  @apply font-bold text-xl;
}

.row {
  @apply flex justify-between w-full mb-2;
}

.col {
  @apply flex flex-col gap-4;
}

.button {
  @apply w-32 ml-auto;
}

.list {
  @apply flex flex-col gap-2;
}
