/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.overlay {
  @apply bg-overlay-backdrop-bg/70 backdrop-blur-[1px];
  @apply cursor-pointer;
  @apply fixed top-0 bottom-0 right-0 left-0 z-50;
}

.container {
  @apply bg-base-100;
  @apply fixed top-1/2 left-1/2 w-[90%] sm:w-[600px] z-50 overflow-hidden;
  @apply rounded-2xl pb-4;
  @apply -translate-x-1/2 opacity-0;
  @apply -translate-y-[700px] transition-transform transition-opacity duration-300;
  @apply flex flex-col;
  @apply pointer-events-none;
}

.container.open {
  @apply -translate-y-1/2 opacity-100;
  @apply pointer-events-auto;
}

.close_btn {
  @apply p-1;
  @apply text-2xl;
}

.content {
  @apply flex-1 overflow-auto;
}

.body {
  @apply flex flex-col;
  @apply w-full max-h-[95vh] p-6;
}

.head {
  @apply flex items-center justify-between;
  @apply mb-7;
}
