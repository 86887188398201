/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.main {
  @apply flex flex-col;
  @apply w-full;
}

.box {
  @apply flex gap-1 items-center;

  & path {
    fill: #1b1a29;
  }
}
