.container {
  @apply px-[28px] py-8;
  @apply bg-white;
  @apply rounded-xl;
  @apply gap-2 flex flex-col;
}

.row {
  @apply flex justify-between items-center;
}

.buttons {
  @apply flex gap-2;
}

.column {
  @apply flex flex-col gap-2;
}

.balanceTitle {
  @apply text-base text-text-40;
}

.balance {
  @apply text-3xl text-text-100 font-bold;
}

.icon {
  @apply mr-1;
}
