/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.button {
  @apply flex items-center justify-center gap-1;
  @apply py-3 px-5;
  @apply rounded-xl;
  @apply text-sm font-semibold;
  @apply transition duration-150;
}

.small {
  @apply py-1 px-3;
  @apply rounded-md;
  @apply text-xs;
}

.large {
  @apply py-4 px-6;
  @apply text-lg;
}

.button:disabled {
  @apply opacity-40;
}

.primary {
  @apply bg-primary-100;
  @apply text-text-05;
  @apply hover:bg-primary-90;
}
.primary:not(:disabled) {
  @apply hover:bg-primary-90;
}

.secondary {
  @apply bg-base-80;
  @apply text-text-100;
}
.secondary:not(:disabled) {
  @apply hover:bg-base-70;
}

.black {
  @apply bg-base-05;
  @apply text-text-05;
}
.black:not(:disabled) {
  @apply hover:bg-base-10;
}

.negative {
  @apply bg-system-error;
  @apply text-base-100;
}
.negative:not(:disabled) {
  @apply hover:bg-system-error_hover;
}

.positive {
  @apply bg-system-success;
  @apply text-base-100;
}
.positive:not(:disabled) {
  @apply hover:bg-system-success_hover;
}
