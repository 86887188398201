.ant-segmented-item {
  @apply h-10;
  @apply flex items-center justify-center;
  @apply px-12;
}
.ant-segmented .ant-segmented-item {
  @apply rounded-2xl;
}
.ant-segmented .ant-segmented-item-selected .ant-segmented-item-label {
  @apply text-black;
}
.ant-segmented .ant-segmented-item-label {
  @apply font-semibold;
}
.ant-segmented .ant-segmented-thumb {
  @apply rounded-2xl;
}
.ant-segmented {
  @apply bg-base-80;
  @apply rounded-2xl;
  @apply p-1;
}
