/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@fontsource-variable/inter';
@import 'flag-icons/css/flag-icons.min.css';

@import './antd/index.css';
@import './json-view/index.css';
@import './charts/charts.css';

@import './variables.css';
@import './titles.css';

body {
  @apply font-primary;
}

body.no-scroll {
  @apply max-h-screen;
  @apply overflow-hidden;
}

button:disabled {
  @apply opacity-50;
}
