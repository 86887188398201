/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.ant-radio-wrapper {
  @apply text-sm text-text-70;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  @apply border-primary-100;
  @apply bg-primary-100;
}

.ant-radio-wrapper .ant-radio-checked::after {
  @apply border-primary-100;
}

.ant-radio-wrapper:hover .ant-radio-inner {
  @apply border-primary-100;
}
