.container {
  @apply h-[28px];
  @apply px-3 rounded-lg;
  @apply flex items-center justify-center;
}

.container.new {
  @apply bg-[#3C72A833];
}
.container.started {
  @apply bg-[#40AE5F33];
}
.container.finished {
  @apply bg-base-90;
}
.container.inactive {
  @apply bg-[#e0232333];
}
.container.deleted {
  @apply bg-[#e0232333];
}
.container.completed {
  @apply bg-base-90;
}
.container.claimed {
  @apply bg-diagram-6;
}

.container.new .status {
  @apply text-base-10;
}
.container.started .status {
  @apply text-[#40AE5F];
}
.container.finished .status {
  @apply text-status-not_started;
}
.container.inactive .status {
  @apply text-[#e02323];
}
.container.deleted .status {
  @apply text-[#e02323];
}
.container.completed .status {
  @apply text-status-not_started;
}
.container.claimed .status {
  @apply text-white;
}

.status {
  @apply text-sm font-medium;
}
