/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.ant-table-wrapper .ant-table,
.ant-empty {
  @apply font-primary;
  @apply text-text-70;
}

.ant-table-thead {
  @apply shadow-table-header hover:shadow-table-header;
}

.ant-table-wrapper .ant-table-thead > tr > th {
  @apply bg-base-100;
}

.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  @apply hidden;
}

.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-thead > tr > td {
  @apply text-text-40;
  @apply text-start text-sm leading-4;
  @apply font-medium;
}

.ant-table-wrapper .ant-table-tbody > tr > th,
.ant-table-wrapper .ant-table-tbody > tr > td {
  @apply text-start text-sm;
  @apply p-4;
}

.ant-table-wrapper
  .ant-table-tbody
  .ant-table-row.ant-table-row-selected
  > .ant-table-cell {
  @apply bg-base-100;
}

.ant-table-wrapper
  .ant-table-tbody
  .ant-table-row.ant-table-row-selected
  > .ant-table-cell-row-hover {
  @apply bg-base-90/60;
}

.ant-table-wrapper .ant-table-tbody .ant-table-row > .ant-table-cell-row-hover {
  @apply text-text-100;
  @apply bg-base-90/60;
}

.table-row-clickable
  .ant-table-wrapper
  .ant-table-tbody
  .ant-table-row
  > .ant-table-cell-row-hover {
  @apply cursor-pointer;
}

.ant-table-wrapper .ant-table-tbody > tr.ant-table-placeholder {
  @apply bg-transparent;
}

.ant-spin-nested-loading > div > .ant-spin {
  @apply max-h-none;
}

.ant-spin .ant-spin-dot-item {
  @apply bg-primary-100;
}

.ant-table-wrapper .ant-table-expanded-row-fixed {
  @apply -m-[17px];
}
