/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.main {
  @apply sticky top-0;
  @apply bg-base-100;
  @apply w-full;
  @apply flex items-center justify-between;
  @apply px-5 py-4;
  @apply z-40;
}

.title_container {
  @apply flex items-center gap-x-8;
}

.title {
  @apply text-3xl;
  @apply font-bold;
}

.user_container {
  @apply flex items-center justify-between gap-x-4;
}

.partner {
  @apply flex flex-col;
  @apply px-2;
}

.partner_name {
  @apply font-semibold;
}

.partner_label {
  @apply text-text-40;
  @apply text-xs;
}
