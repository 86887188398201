/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.main {
  @apply sticky top-0;
  @apply min-h-screen h-fit;
  @apply w-full max-w-20 sm:max-w-64;
  @apply bg-base-100;
  @apply flex flex-col items-center sm:items-start;
  @apply transition-all duration-100;
}

.links {
  @apply flex flex-col gap-y-2;
  @apply w-full;
  @apply px-3 pt-6;
}

.link {
  @apply text-text-100;
  @apply font-semibold text-sm;
  @apply rounded-2xl;
  @apply flex items-center justify-center sm:justify-start gap-x-3;
  @apply p-4;
  @apply transition-colors duration-300;
  @apply w-full;
}

.link > span {
  @apply hidden sm:inline;
}

.link > svg {
  @apply text-primary-100;
  @apply shrink-0;
  @apply w-6 h-6;
}

.link.active {
  @apply bg-primary-100 bg-primary-100;
}

.link.active > svg {
  @apply text-base-100;
}

.link.active > span {
  @apply text-base-100;
}

.link.hoverable:not(.active) {
  @apply hover:bg-primary-100/20;
}

.footer {
  @apply mt-auto;
  @apply w-full;
}

.footer_btn {
  @apply flex items-center justify-center sm:justify-start;
}

.collapse_btn {
  @apply hidden sm:flex;
}

.collapsed {
  @apply max-w-20;
  @apply items-center;
}

.collapsed .link,
.collapsed .footer_btn {
  @apply justify-center;
}

.collapsed .logo_text,
.collapsed .link > span {
  @apply hidden;
}

.collapsed .collapse_btn {
  @apply rotate-180;
}
