/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.main {
  @apply w-full max-w-7xl;
  @apply mx-auto;
  @apply flex-1;
  @apply p-4;
}
