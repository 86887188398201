.container {
  @apply flex flex-col max-w-[700px];
}

.row {
  @apply flex justify-between gap-3;
}

.buttons {
  @apply flex gap-2;
}

.search {
  @apply w-60;
}

.table .ant-table-tbody {
  @apply max-h-[500px];
}
