/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.ant-picker {
  @apply font-primary font-semibold;
  @apply bg-base-90;
  @apply text-text-40;
  @apply rounded-2xl;
}

.ant-picker-outlined {
  @apply bg-base-90;
}

.ant-picker-outlined:hover,
.ant-picker-outlined:focus,
.ant-picker-outlined:focus-within {
  @apply border-primary-100;
  @apply bg-base-90;
}

.ant-picker .ant-picker-input > input {
  @apply text-text-100/80 font-medium;
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  @apply border-primary-100;
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-selected:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner {
  @apply bg-primary-100;
}

.ant-picker.ant-picker-range {
  @apply w-full;
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-in-range:not(
    .ant-picker-cell-disabled
  ):before,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-disabled
  ):before,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-disabled
  ):before {
  @apply bg-primary-100/15;
}

.ant-picker-range .ant-picker-active-bar {
  @apply bg-primary-100;
}

.ant-picker-dropdown .ant-picker-panel-container {
  @apply rounded-2xl;
}
