/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.recharts-responsive-container,
.recharts-wrapper {
  @apply text-xs font-medium;
  @apply text-text-40;
}

.recharts-cartesian-axis-tick-value {
  @apply fill-text-40;
}

.recharts-default-tooltip {
  @apply rounded-2xl;
}
