/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.apply_btn {
  @apply mt-8;
}
.two_cols {
  @apply grid grid-cols-2 gap-x-3;
}

.three_cols {
  @apply grid grid-cols-3 gap-x-3;
}
.three_cols > div {
  @apply max-w-[160px];
}

.container {
  @apply flex flex-col gap-6;
}

.col {
  @apply flex flex-col;
}

.buttons {
  @apply grid grid-cols-2 gap-2;
}

.status-container {
  @apply mt-[-44px] h-[28px] flex justify-between mb-[10px] mr-10 ml-[170px] items-center;
}

.status-button {
  @apply cursor-pointer;
}

.status-list {
  @apply w-[120px] flex flex-col justify-center gap-2;
}

.timer {
  @apply flex;
}

.timer-container {
  @apply flex justify-between;

  .ant-radio-group > div {
    @apply flex;
  }
}
.timer-container :global .ant-radio-group > div {
  @apply flex;
}

.upload {
  @apply border-dashed border-primary-60 border rounded-2xl;
  @apply w-full h-60;
  @apply flex items-center justify-center flex-col gap-4;
  @apply cursor-pointer;
  @apply text-xl font-medium;
}

.upload_wrapper :global .ant-upload {
  @apply w-full cursor-pointer;
}
.upload_wrapper {
  @apply relative;
}

.btn {
  @apply mt-3;
}

.container h5 {
  @apply font-semibold;
}

.error {
  @apply text-system-error;
  @apply px-1;
  @apply text-sm;
}

.upload_content {
  @apply flex flex-col gap-8;
}

.upload_content_file {
  @apply rounded-full bg-base-05 bg-opacity-5 relative p-5;
  @apply flex justify-center items-center;
}

.upload_file_svg {
  @apply absolute bottom-[-6px] right-[-6px];
  @apply rounded-full bg-base-05 p-2;
}

.image_item {
  @apply flex items-center gap-3 p-5;
  @apply border-solid border-base-80 border rounded-2xl;
}
.image_item img {
  @apply flex;
}

.image_name {
  @apply font-semibold text-base max-w-[200px] break-words;
}

.image_actions {
  @apply flex gap-3 ml-auto mr-[56px];
}

.image_action_container {
  @apply flex justify-center items-center w-11 h-11 p-[10px] rounded-full bg-base-90 cursor-pointer;
}

.image_action_container svg {
  @apply w-6 h-6;
}

.image_upload :global .ant-upload-select {
  @apply w-11 h-11;
  @apply absolute bottom-[50%] right-[20px] flex rounded-full bg-base-90 cursor-pointer;
  @apply translate-y-[50%];
}

.image_upload :global .ant-upload-select svg {
  @apply w-5 h-5;
}

.cost-hash-hint {
  @apply text-system-success;
  @apply px-1 -mt-5;
  @apply text-sm;
}
