/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.main {
  @apply relative;
  @apply bg-base-100 rounded-2xl;
  @apply px-5 py-6;
  @apply flex flex-col items-center gap-y-6;
  @apply overflow-hidden;
}

.header {
  @apply w-full;
  @apply flex flex-col items-center gap-2;
}

.name {
  @apply w-full;
  @apply text-center;
  @apply text-2xl;
  @apply font-bold;
}

.userId {
  @apply bg-primary-100/20 rounded-2xl;
  @apply px-3 py-2;
  @apply text-xs;
  @apply font-medium;
  @apply flex items-center justify-between gap-3;
}

.userId button {
  @apply text-primary-100/80;
}

.body {
  @apply grid grid-cols-1 gap-2;
  @apply w-full;
}

.body_group {
  @apply grid grid-cols-2 gap-2;
  @apply w-full;
}

.body_group > *:only-child {
  @apply col-span-full;
}

.edit_btn {
  @apply w-full;
  @apply flex items-center justify-end;
}

.edit_btn > button {
  @apply text-xs;
  @apply !px-4 !py-1;
  @apply flex items-center justify-center gap-1;
}
