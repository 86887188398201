/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.main {
  @apply rounded-xl;
  @apply font-medium text-xs text-center;
  @apply py-1 px-3;
}

.main.success {
  @apply bg-status-approved/20;
  @apply text-status-approved;
}

.main.warning {
  @apply bg-status-in_progress/20;
  @apply text-status-in_progress;
}

.main.danger {
  @apply bg-status-declined/20;
  @apply text-status-declined;
}

.main.neutral {
  @apply bg-base-90;
  @apply text-text-40;
}
