/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.container {
  @apply mx-2;
  @apply flex items-center justify-center gap-x-6 gap-y-2;
  @apply flex-wrap;
}

.link {
  @apply text-text-70;
  @apply border-b-[3px] border-b-transparent;
  @apply pb-1;
}

.link.active {
  @apply font-semibold text-text-100;
  @apply border-b-primary-100;
}
