/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.main {
  @apply flex flex-col gap-y-4;
  @apply w-full;
}

.header {
  @apply flex items-center justify-between;
}

.total {
  @apply text-text-40;
  @apply text-sm;
}

.count {
  @apply text-text-100 font-medium;
  @apply text-base;
  @apply px-1;
}
