/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

html {
  --font-primary: 'Inter Variable', sans-serif;

  --table-header-shadow: 0px 4px 8px 0px #0000000d;

  --overlay-backdrop-bg: 0, 0, 0;

  --tooltip-bg: #16151e;
  --tooltip-text: #ffffff;

  --scrollbar-width: 4px;
  --scrollbar-color: #8e8e98;
}
