/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.list {
  @apply my-1;
  @apply grid grid-flow-row gap-1;
}

.title {
  @apply flex justify-between items-center;
}

.btn {
  @apply hover:text-secondary-80;
}

.btn > svg {
  @apply transition duration-100;
}

.content_hidden > svg {
  @apply rotate-180;
}

.label {
  @apply flex items-center gap-x-2;
}

.label > img,
.label > svg {
  @apply w-5 h-5;
  @apply rounded-full;
}
