.container {
  @apply flex justify-between overflow-hidden gap-3;
  @apply bg-base-90  p-[16px_12px] rounded-sm;
}

.wrapper {
  @apply flex gap-3 items-center flex-col;
}

.content {
  @apply flex flex-col gap-3 w-full;
}

.two_cols {
  @apply grid grid-cols-2 gap-x-3;
}

.col {
  @apply flex flex-col;
}

.row {
  @apply flex justify-between items-center;
}
