/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.title {
  @apply flex justify-between items-center;
}

.btn {
  @apply hover:text-secondary-80;
}

.btn > svg {
  @apply transition duration-100;
}

.content_hidden > svg {
  @apply rotate-180;
}

.dropdown {
  @apply flex flex-col gap-3;
  @apply w-80 max-h-96;
  @apply overflow-auto;
}

.dropdown-btn {
  @apply rounded-full;
}

.dropdown-btn-row {
  @apply flex justify-end gap-1;
}

.list-container {
  @apply flex flex-col gap-4;
  @apply overflow-auto;
  @apply ml-2;
}

.list {
  @apply flex flex-col gap-2;
  @apply w-full;
}

.text {
  @apply flex items-center gap-1;
  @apply text-xs text-base-50;
}

.text div:nth-of-type(1) {
  @apply shrink-0;
}

.text-divider {
  @apply h-[1px] w-full;
  @apply bg-base-80;
  @apply mr-4;
}

.labels {
  @apply flex flex-col gap-3;
}

.checkbox-label {
  @apply flex-row-reverse;
  @apply mr-4;
}

.checkbox-hidden {
  @apply hidden;
}

.checkbox-label span:nth-of-type(2) {
  @apply block;
  @apply mr-auto;
  @apply p-0;
}

.label {
  @apply flex gap-2;
}

.label > img,
.label > span,
.label > svg {
  @apply w-5 h-5;
  @apply rounded-full;
}

.count {
  @apply text-sm text-base-60;
  @apply mt-2;
}
