/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.list {
  @apply flex flex-col;
}

.item {
  @apply px-2 py-4 font-medium;
  @apply flex items-center justify-between;
  @apply cursor-pointer;
}

.item:not(:last-child) {
  @apply border-b border-b-base-80;
}

.item_info {
  @apply flex flex-col items-start;
}

.item_info_additional {
  @apply text-xs text-text-40;
}
