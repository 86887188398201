/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.main {
  @apply flex flex-row gap-4 flex-wrap;
  @apply w-full;
}

.col2 {
  @apply w-full flex flex-col gap-y-4 lg:w-1/3;
  @apply bg-base-100 p-4 rounded-3xl;
}

.col {
  @apply w-60 flex flex-col gap-y-4;
}

.chart_container {
  @apply w-full;
  @apply h-[190px];
}

.chart_big {
  @apply h-[240px];
}
