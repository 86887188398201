.ant-switch[type='button'] {
  @apply bg-base-40;
}
.ant-switch.ant-switch-checked {
  @apply bg-primary-100;
}

.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  @apply bg-primary-90;
}
