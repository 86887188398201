.container {
  @apply p-3 rounded-lg;
  @apply flex flex-col gap-2;
  @apply bg-base-05 bg-opacity-5;
}

.row {
  @apply flex items-center justify-between;
}

.title {
  @apply text-text-60;
  @apply text-sm font-medium;
}

.value {
  @apply font-semibold;
}
