.main {
  @apply flex-1;
  @apply flex flex-col gap-y-3;
}

.two_cols {
  @apply grid grid-cols-2 gap-x-3;
}

.block {
  @apply flex flex-col gap-x-3;
}

.row {
  @apply flex gap-x-1;
}

.actions {
  @apply mt-auto;
  @apply pt-2;
  @apply grid grid-cols-2 gap-4;
  @apply w-full;
}

.button {
  @apply w-max;
}

.tabs {
  @apply flex;
}

.tabs > div {
  @apply m-0;
}
