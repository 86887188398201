/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.list {
  @apply flex flex-col gap-2;
}

.container {
  @apply flex flex-col gap-3;
}

.row {
  @apply flex justify-between items-center;
}

.description {
  @apply text-sm text-base-60;
}

.wrapper {
  @apply flex items-center gap-4;
}
