/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.currencies_grid {
  @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2;
}

.currency {
  @apply flex flex-col gap-2 w-full;
  @apply border border-base-80 p-4;
  @apply rounded-2xl;
}

.img_section {
  @apply flex gap-2;
}

.currency_img {
  @apply w-7 h-7;
  @apply rounded-full;
}

.currency_placeholder {
  @apply bg-base-50/20;
}

.short_name {
  @apply text-text-100 text-lg;
  @apply font-semibold;
}

.currency_info_row {
  @apply flex items-end justify-between;
  @apply -mt-1;
}

.currency_info_name {
  @apply flex flex-col;
}

.description {
  @apply text-text-40;
  @apply text-xs font-medium;
}

.price {
  @apply font-semibold;
}
