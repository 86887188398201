.cell {
  @apply flex flex-col p-4;
}

.table tbody tr td:not(:nth-child(1)):hover {
  @apply bg-base-90 cursor-pointer;
}

.table tbody tr td:not(:nth-child(1)) {
  @apply p-0;
}

.table tbody tr td:nth-child(1) {
  @apply text-base-60 font-medium;
}

.title {
  @apply text-xs text-base-50;
}

.descr {
  @apply text-xs font-medium;
}
