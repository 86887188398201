/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.main {
  @apply flex items-center justify-between;
}

.main > div {
  @apply bg-base-100 text-text-100;
  @apply p-3;
  @apply rounded-full;
}

.main > span {
  @apply text-xl font-semibold;
  @apply mx-3;
}
