/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.main {
  @apply rounded-xl border;
  @apply font-medium text-sm text-center;
  @apply p-3 h-[188px];
  @apply flex flex-col justify-between;
  @apply bg-health-excellent_bg border-health-excellent;
}

.catastrophe {
  @apply bg-health-excellent_bg border-health-excellent/20;
}
.panic {
  @apply bg-health-caution_bg border-health-caution;
}
.problems {
  @apply bg-health-dangerous_bg border-health-dangerous/90;
}
.good {
  @apply bg-health-catastrophe_bg border-health-catastrophe;
}

.header {
  @apply flex justify-between;
}
.header_text {
  @apply flex flex-col items-start text-text-70;
}
.percent {
  @apply font-bold text-3xl text-text-100;
}

.indicator_container {
  @apply relative pt-7;
}
.indicator_point {
  @apply absolute top-0 -ml-3;
}
.indicator {
  @apply w-full flex flex-row h-2 rounded-3xl overflow-hidden;
}
.indicator .red {
  @apply w-[25%] bg-health-catastrophe;
}
.indicator .orange {
  @apply w-[25%] bg-health-dangerous;
}
.indicator .yellow {
  @apply w-[17%] bg-health-caution;
}
.indicator .green {
  @apply w-[33%] bg-health-excellent;
}

.indicator_text {
  @apply flex justify-between pt-1 text-health-excellent;
}
.indicator_text span:first-child {
  @apply text-health-catastrophe;
}
