/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.counters_grid {
  @apply grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2;
}

.counter {
  @apply border border-base-80 p-4;
  @apply rounded-2xl;
  @apply col-span-1;
  @apply flex flex-col gap-y-1;
}

.counter > span {
  @apply text-text-40;
  @apply text-base font-medium;
  @apply -mb-2;
}
