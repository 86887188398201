.container {
  @apply flex flex-col gap-[2px] rounded-2xl;
  @apply px-3 py-2;
  @apply border border-solid border-base-80;
}

.title {
  @apply text-sm font-medium text-text-40;
}
.value {
  @apply font-semibold;
}
