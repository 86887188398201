/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.card {
  @apply relative;
  @apply rounded-2xl;
  @apply p-5;
  @apply flex flex-col gap-y-2;
  @apply overflow-hidden;
  @apply border border-base-80 border-solid;
}

.p {
  @apply text-sm font-medium;
  @apply text-text-70;
}

.row {
  @apply flex items-center justify-between gap-x-2;
}

.date {
  @apply text-sm font-medium;
}

.actions {
  @apply flex flex-col gap-y-1.5 items-start;
}

.action {
  @apply text-base font-medium;
  @apply text-text-60;
  @apply cursor-pointer;
  @apply w-full text-left;

  @apply hover:text-text-100 transition-colors;
}

.buttons {
  @apply flex gap-x-2;
  @apply ml-auto;
}

.edit {
  @apply flex gap-y-3 flex-col;
}

.button {
  @apply w-28;
}
