/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.wrapper {
  @apply flex flex-col gap-3;
}

.steps {
  @apply flex flex-col gap-3;
}

.step {
  @apply flex justify-between items-center;
}

.step-select {
  @apply w-[260px];
}

.btn {
  @apply mt-3;
}

.btns {
  @apply grid grid-cols-2 gap-4;
}
