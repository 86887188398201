/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.card {
  @apply relative;
  @apply bg-base-100 rounded-2xl;
  @apply px-5 py-6;
  @apply flex flex-col items-center gap-y-6;
  @apply overflow-hidden;
}

.card h1 {
  @apply font-semibold text-xl;
}

.card h2 {
  @apply font-semibold text-xl m-0;
}

.card h3 {
  @apply font-semibold text-base m-0;
}

.row {
  @apply flex flex-wrap justify-between items-center;
  @apply w-full mb-0;
}

.row h2 {
  @apply m-0;
}

.col {
  @apply flex flex-col gap-4;
}

.container {
  @apply flex gap-2;
  @apply w-full;
}

.container > button {
  @apply flex-1;
}

.title {
  @apply font-semibold text-2xl;
}
