/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.main {
  @apply bg-base-90;
  @apply flex w-full;
  @apply min-h-screen;
}
