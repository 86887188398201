.upload {
  @apply border-dashed border-text-60 border rounded-md;
  @apply w-full h-20;
  @apply flex items-center justify-center;
  @apply cursor-pointer;
}

.upload_wrapper :global .ant-upload {
  @apply w-full cursor-pointer;
}

.wrapper {
  @apply flex flex-col gap-3;
}

.btn {
  @apply mt-3;
}
