.details {
  @apply flex flex-col gap-y-2;
  @apply p-3;
  @apply bg-base-90;
  @apply rounded-2xl;
}

.details-item {
  @apply flex justify-between items-center;
}

.block {
  @apply flex flex-col gap-x-3;
}

.row {
  @apply flex gap-x-1 justify-between;
}

.button {
  @apply w-max;
}

.details-label {
  @apply text-text-60;
  @apply font-medium text-sm;
}

.details-value {
  @apply text-black;
  @apply font-semibold text-base;
}

.input {
  @apply rounded-md px-2;
}

.edit {
  @apply text-xs font-semibold;
  @apply bg-base-80 rounded-2xl h-[28px] px-3;
}
