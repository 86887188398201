/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

html h1,
html h2,
html h3,
html h4,
html h5,
html h6 {
  @apply font-bold text-text-100 my-2 leading-none;
}

html h1 {
  @apply text-4xl;
}

html h2 {
  @apply text-[2rem];
}

html h3 {
  @apply text-[1.625rem];
}

html h4 {
  @apply text-[1.375rem];
}

html h5 {
  @apply text-[1rem];
}

html h6 {
  @apply text-text-40;
  @apply text-[1rem];
}
