/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.select_container {
  @apply w-full;
}

.select_container > div {
  @apply w-full;
}

.error {
  @apply text-system-error;
  @apply px-1;
  @apply text-sm;
}
