.container {
  @apply grid grid-cols-2 gap-4;
}

.card {
  @apply bg-white rounded-xl;
  @apply px-5 py-6;
  @apply flex flex-col gap-2;
}

.row {
  @apply flex gap-2 items-center;
}

.balance {
  @apply text-xl font-semibold;
}

.secondBalance {
  @apply text-base text-text-40;
}
