.box {
  @apply flex flex-col gap-3;
}

.column {
  @apply flex flex-col gap-2;
}

.title {
  @apply text-xl font-bold;
}

.container {
  @apply flex flex-col gap-[28px];
}

.card {
  @apply flex flex-col gap-4;

  @apply bg-base-90 p-3;
  @apply rounded-xl;
}

.row {
  @apply flex justify-between items-center;
}

.name {
  @apply text-sm font-semibold;
}

.label {
  @apply text-sm font-semibold;
}

.error {
  @apply text-system-error;
  @apply px-1;
  @apply text-sm;
}

.balance {
  @apply text-base-50;
  @apply font-semibold text-sm;
}

.swap {
  @apply flex items-center gap-5;
}
