/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.section {
  @apply flex flex-col gap-y-6;
  @apply bg-base-100 rounded-2xl;
  @apply p-4;
}

.header {
  @apply flex flex-col md:flex-row md:items-center justify-between;
}

.additional_info {
  @apply text-text-40;
  @apply font-medium;
}
