/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.main {
  @apply relative;
  @apply flex items-center justify-center gap-2;
}

.active_label {
  @apply pointer-events-none;
  @apply absolute -top-2 -right-2;
  @apply bg-base-05 text-base-100;
  @apply flex items-center justify-center;
  @apply h-6 w-6 rounded-full;
  @apply text-xs font-semibold;
}
