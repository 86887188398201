/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.main {
  @apply flex flex-col gap-4;
}

.header {
  @apply flex items-center justify-between gap-2;
  @apply flex-wrap md:flex-nowrap;
}

.body {
  @apply grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4;
}

.basic_info {
  @apply col-span-1;
}

.tab_container {
  @apply col-span-1 xl:col-span-2;
}
