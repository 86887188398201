/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.actions {
  @apply flex flex-col gap-y-1.5 items-start;
}

.action {
  @apply text-base font-medium;
  @apply text-text-60;
  @apply cursor-pointer;
  @apply w-full text-left;

  @apply hover:text-text-100 transition-colors;
}

.btn-more {
  @apply flex justify-center;
}
