/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.main {
  @apply grid justify-items-end items-end gap-y-4;
}

.table_container {
  @apply flex flex-col relative w-full;
  @apply overflow-x-auto overflow-y-hidden;
  @apply bg-base-100 rounded-2xl;
}
