.ant-tag {
  @apply text-sm font-semibold;
  @apply h-10 px-4 m-0;
  @apply flex items-center;
  @apply rounded-xl;
  @apply bg-base-80;
}

.ant-tag .ant-tag-close-icon {
  @apply text-xs font-semibold;
  @apply text-black;
}
