/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.container {
  @apply flex flex-col gap-4;
}

.btn {
  @apply h-8;
}

.row {
  @apply flex justify-between items-center;
}

.title {
  @apply font-semibold text-base;
}

.table-title {
  @apply font-medium text-base-60;
}

.empty {
  @apply flex items-center justify-center p-2;
}

.grid-row {
  @apply grid grid-cols-[1fr_1fr_220px] items-center gap-3;
}
.btns {
  @apply grid gap-2 grid-cols-[108px_108px];
}
