.container {
  @apply flex flex-col gap-3;
  @apply p-3 bg-base-90;
  @apply rounded-lg;
}

.title {
  @apply text-sm font-medium text-text-40;
}

.value {
  @apply text-base font-semibold;
}

.row {
  @apply bg-base-100 px-3 py-2;
  @apply rounded-lg;
  @apply flex flex-col gap-[2px];
}
