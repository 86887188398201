.box {
  @apply flex flex-col gap-3;
}

.title {
  @apply text-xl font-bold;
}

.container {
  @apply flex flex-col gap-[28px];
}

.card {
  @apply flex flex-col gap-2;

  @apply bg-base-90 p-3;
  @apply rounded-xl;
}

.button {
  @apply mt-2 mb-1 mx-auto;
  @apply max-w-[155px];
}

.row {
  @apply flex justify-between items-center;
}

.name {
  @apply text-sm font-semibold;
}

.label {
  @apply text-sm font-semibold;
}

.error {
  @apply text-system-error;
  @apply px-1;
  @apply text-sm;
}
