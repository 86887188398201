.ant-input-lg {
  @apply rounded-xl;
}

.ant-input-outlined:not(.ant-input-disabled):hover {
  @apply border-primary-100;
}

.ant-input-outlined:focus,
.ant-input-outlined:focus-within {
  @apply border-primary-100;
}

.ant-input-group-wrapper-outlined .ant-input-group-addon {
  @apply bg-transparent;
}

.ant-input-group-wrapper-lg .ant-input-group-addon {
  @apply rounded-xl;
  @apply p-0;
}

.ant-input-group-wrapper .ant-select {
  @apply bg-white;
}

.ant-input-group-wrapper .ant-select.ant-select-lg {
  @apply h-10;
}

.ant-input-group-wrapper .ant-select-selector {
  @apply !rounded-tl-none !rounded-bl-none;
}
