/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.overlay {
  @apply bg-overlay-backdrop-bg/70 backdrop-blur-[1px];
  @apply cursor-pointer;
  @apply fixed top-0 bottom-0 right-0 left-0 z-50;
}

.container {
  @apply bg-base-100;
  @apply fixed top-0 bottom-0 right-0 w-full sm:min-w-[430px] sm:w-max sm:max-w-[600px] z-50 overflow-hidden;
  @apply translate-x-full transition-transform duration-200;
  @apply flex flex-col;
}

.container.open {
  @apply translate-x-0;
}

.close_btn {
  @apply p-1 -mr-2;
  @apply text-2xl;
}

.content {
  @apply flex-1 overflow-auto;
}

.body {
  @apply flex flex-col;
  @apply w-full min-h-[100vh] p-6;
}

.head {
  @apply flex items-center justify-between;
  @apply mb-3;
}
