.wrapper {
  @apply flex flex-col gap-3;
}

.btn {
  @apply mt-3;
}

.btns {
  @apply grid grid-cols-2 gap-4;
}

.title {
  @apply text-center text-xl;
}
.inputs {
  @apply flex flex-col gap-3;
}
