/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.react-json-view {
  @apply break-all;
  @apply p-2;
}

.copy-to-clipboard-container > span > span {
  @apply flex;
}
