.container {
  @apply flex flex-col gap-5;
  @apply bg-base-100 rounded-2xl;
  @apply px-5 py-6;
}

.card {
  @apply flex flex-col gap-4;
  @apply bg-base-100 rounded-2xl;
  @apply p-5;
  @apply border border-solid border-base-80;
}

.info {
  @apply flex justify-between flex-wrap xl:flex-nowrap;
}

.cards {
  @apply flex flex-col gap-3;
}

.info_container {
  @apply flex flex-wrap gap-2;
}

.info_container__item {
  @apply w-[130px] flex-1;
}

.info_container__item_stretch {
  @apply flex-1 min-w-[130px] flex-grow-[3];
}

.progress_container {
  @apply w-full rounded-[20px] relative h-2;
  @apply bg-[#FFDCCC];
}
.progress {
  @apply absolute h-full left-0 top-0;
  @apply w-full rounded-[20px];
  @apply bg-[#FF5102];
}
