/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.ant-notification {
  @apply font-primary;
}

.ant-notification .ant-notification-notice-wrapper {
  @apply !overflow-hidden rounded-2xl;
}

.ant-notification-notice.info {
  @apply bg-notification-information/70;
}
.ant-notification-notice.success {
  @apply bg-notification-success/70;
}
.ant-notification-notice.error {
  @apply bg-notification-error/70;
}
.ant-notification-notice.warning {
  @apply bg-notification-warning/70;
}

.ant-notification
  .ant-notification-notice-wrapper
  .ant-notification-notice.error
  .ant-notification-notice-icon {
  @apply text-system-error;
}

.ant-notification
  .ant-notification-notice-wrapper
  .ant-notification-notice.warning
  .ant-notification-notice-icon {
  @apply text-system-warning;
}

.ant-notification-notice {
  @apply !p-4;
}

.ant-notification-notice-message {
  @apply font-semibold;
}
.ant-notification-notice-description {
  @apply font-medium;
}

.ant-notification
  .ant-notification-notice-wrapper
  .ant-notification-notice-with-icon
  .ant-notification-notice-message {
  @apply mb-0;
}

.ant-notification
  .ant-notification-notice-wrapper
  .ant-notification-notice-close {
  @apply top-4 right-4;
}

.ant-notification
  .ant-notification-notice-wrapper
  .ant-notification-notice-btn {
  @apply float-left mb-0;
}

.ant-notification-notice-btn:empty {
  @apply !m-0;
}
