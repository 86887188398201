/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

.apply_btn {
  @apply mt-auto;
}

.table {
  @apply max-w-[800px];
}
